import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="reveal"
export default class extends Controller {
  static targets = ["revealable", "openButton", "closeButton"]
  connect() {}

  toggle(event) {
    this.openButtonTarget.classList.toggle("hidden")
    this.closeButtonTarget.classList.toggle("hidden")
    this.revealableTarget.classList.toggle("hidden")
  }
}
